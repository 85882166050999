import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useStoreUser } from '@/stores/storeUser';
import MainPage from '@/views/MainPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Index',
    path: '/',
    redirect: '/login',
  },

  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
  },

  {
    name: 'Main',
    path: '/main/',
    component: MainPage,
    children: [
      {
        path: '',
        redirect: '/main/record',
      },

      {
        path: 'home',
        component: () => import('@/views/main/MainRecordPage.vue'),
      },

      {
        path: 'record',
        component: () => import('@/views/main/MainRecordPage.vue'),
      },

      // {
      //   path: 'image',
      //   component: () => import('@/views/main/MainImagePage.vue'),
      // },

      // {
      //   path: 'place',
      //   component: () => import('@/views/main/MainPlacePage.vue'),
      // },

      {
        path: 'map',
        component: () => import('@/views/main/MainMapPage.vue'),
      },

      {
        path: 'poll',
        component: () => import('@/views/main/MainPollPage.vue'),
      },

      {
        path: 'gathering',
        component: () => import('@/views/main/MainGatheringPage.vue'),
      },

      {
        path: 'ranking',
        component: () => import('@/views/main/MainRankingPage.vue'),
      },

      {
        path: 'my-space',
        component: () => import('@/views/main/MainMySpacePage.vue'),
      },
    ],
  },

  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { isLoginChecked, isLoggedIn } = useStoreUser();

  // @NOTE: Login 여부 (Token Check) 확인이 안된 경우, 우선 Route 진입 하도록 설정
  // 최초 진입의 경우, 다른 곳에서 진입 가능 여부 확인
  if (!isLoginChecked) return next();

  if (to.name !== 'Login' && !isLoggedIn) next({ name: 'Login' });
  else next();
});

export default router;
