import { IMAGE_MAX_SIZE } from '@/constants/rules';
import { useStoreCommon } from '@/stores/storeCommon';
import { useStoreSubPage } from '@/stores/storeSubPage';
import { BasicObject } from '@/types/global';
import { checkIsMobileSize } from '@/utils/ui';
import { Cloudinary } from '@cloudinary/url-gen';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { extractPublicId } from 'cloudinary-build-url';
import { useApi } from './useApi';

export const useImage = () => {
  const { showToast, showImageInput } = useStoreCommon();
  const { showImageCrop } = useStoreSubPage();

  const getImageUrl = (publicId: string, options: BasicObject = {}) => {
    const { size, isThumbnail = false } = options;

    const cloudName = process.env.VUE_APP_CLOUDINARY_CLOUD_NAME;
    const cloudinary = new Cloudinary({ cloud: { cloudName } });
    const myImg = cloudinary.image(publicId);

    if (size) {
      myImg.resize(thumbnail().width(size).height(size));
    } else if (isThumbnail) {
      const isMobileSize = checkIsMobileSize();
      const thumbnailSize = isMobileSize ? 150 : 250;
      myImg.resize(thumbnail().width(thumbnailSize).height(thumbnailSize));
    }
    return myImg;
  };

  const uploadImage = async (type = 'Record') => {
    const { data: imageFile, meta: imageMetaInfo } = await showImageInput();
    const croppedImage = await showImageCrop(imageFile);
    const resizedImage = await resizeImage(croppedImage);
    const imageDatetime = getImageOriginalDate(imageMetaInfo, imageFile);

    const formData = new FormData();
    formData.append('file', resizedImage);
    formData.append('fileType', 'image');
    formData.append('fileCategory', type);
    formData.append('date', imageDatetime.toISOString());

    const response = await useApi('createImage', { data: formData });
    // if (type === 'record')
    //   response = await useApi('createImage', { data: formData });
    // else response = await useApi('uploadFile', { data: formData });

    const {
      data: { image },
    } = response;
    return image;
  };

  const dataURItoBlob = function (dataURI: string) {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: 'image/jpeg' });
  };

  const resizeImage = (file: Blob, maxSize = IMAGE_MAX_SIZE): Promise<Blob> => {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = function () {
      let width = image.width;
      let height = image.height;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg');
      return dataURItoBlob(dataUrl);
    };

    return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }

      reader.onload = function (readerEvent) {
        image.onload = function () {
          return ok(resize());
        };
        image.src = readerEvent?.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  const getImageOriginalDate = (
    imageMetaInfo: BasicObject,
    imageFile: BasicObject
  ) => {
    const lastModifiedDate = imageFile.lastModifiedDate;
    const metaDateTimeValue = imageMetaInfo['DateTimeOriginal']?.value;
    const metaDateTime = metaDateTimeValue?.[0];

    if (!metaDateTime && !lastModifiedDate) {
      // showToast('이미지의 날짜 정보를 읽을 수 없어 현재 시간으로 기록 됩니다.');
      console.log('getImageOriginalDate() - no metaDateTime, lastModifiedDate');
      return new Date();
    }

    if (!metaDateTime) {
      // showToast(
      //   '이미지의 날짜 정보를 읽을 수 없어 파일의 수정 시간으로 기록 됩니다.'
      // );
      console.log('getImageOriginalDate() - no metaDateTime');
      return new Date(lastModifiedDate);
    }

    const metaDateTimes = metaDateTime.split(' ');
    const imageDay = metaDateTimes[0].replace(/:/g, '-');
    const imageTime = metaDateTimes[1];
    const imageDate = new Date(`${imageDay} ${imageTime}`);
    return imageDate;
  };

  return {
    getImageUrl,
    uploadImage,
    extractPublicId,
  };
};
