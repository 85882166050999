import { DBObjectIdColumn } from '@/types/common/apiTypes';
import _ from 'lodash';

// JWT 의 payload 를 파싱해서 리턴하는 함수
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

// String을 특정 길이로 자르는 함수
export const shortenText = (text: string, limit = 100, isEllipsis: boolean) => {
  if (typeof text !== 'string') return '';
  const isOverLimit = text.length > limit;
  const shortedText = text.slice(0, limit);
  if (isOverLimit && isEllipsis) return `${shortedText}...`;
  return shortedText;
};

// Null, Undefined 체크 함수
export const isNull = (target: string) => {
  return target == null || typeof target === 'undefined';
};

// object null 체크함수
export const isEmpty = (object: object) => {
  return Object.keys(object).length === 0;
};

// Null, Undefined, Empty String 체크 함수
export const isEmptyString = (target: any) => {
  const isString = typeof target === 'string';

  if (isString) {
    return target.trim() === '';
  } else {
    return isNull(target);
  }
};

// 배열을 특정 길이 보다 작은 부분은 특정 값으로 채워서 리턴하는 함수
export const fillArray = (arr: Array<any>, length: number, value: any) => {
  const fillCount = length - arr.length;
  return [...arr, ...Array(fillCount).fill(value)];
};

// 랜덤한 ID 를 생성하는 함수
export const getRandomId = (length?: number) => {
  const idLength = length || 11;
  return Math.random().toString(36).substr(2, idLength);
};

// Debounce 함수를 생성하는 함수
let timer: any;
export const createDebounce = (func: () => any, delay: number) => {
  return function (...args: any) {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
};

// Object 깊은 복사하는 함수
export const cloneDeep = (obj: any) => {
  return _.cloneDeep(obj);
};

// Array의 두 index의 값을 서로 바꾸는 함수
export const swapArrayIndex = (
  arr: Array<any>,
  index1: number,
  index2: number
) => {
  const temp = arr[index1];
  arr[index1] = arr[index2];
  arr[index2] = temp;
  return arr;
};

// MongoDB의 ObjectId 칼럼 중 Populated 되었는지 판단하여,
// 다른 ObjectId를 비교하는 함수
export const compareObjectId = (
  obj1: DBObjectIdColumn,
  obj2: DBObjectIdColumn
) => {
  const _objId1 = !obj1 ? '' : typeof obj1 === 'object' ? obj1._id : obj1;
  const _objId2 = !obj2 ? '' : typeof obj2 === 'object' ? obj2._id : obj2;
  return _objId1 === _objId2;
};

// 배열에서 Random Index를 생성하는 함수
export const getRandomIndex = (length: number) => {
  return Math.floor(Math.random() * length);
};

// 배열에서 Random Item을 생성하는 함수
export const getRandomItem = (arr: Array<any>) => {
  const randomIndex = getRandomIndex(arr.length);
  return arr[randomIndex];
};

// 배열에서 특정한 개수 만큼 Random Item을 생성하는 함수
export const getRandomItems = (arr: Array<any>, count: number) => {
  const randomItems = [];
  const randomIndexes: number[] = [];

  while (randomIndexes.length < count && randomIndexes.length < arr.length) {
    const randomIndex = getRandomIndex(arr.length);
    if (!randomIndexes.includes(randomIndex)) {
      randomIndexes.push(randomIndex);
      randomItems.push(arr[randomIndex]);
    }
  }

  return randomItems;
};
