import { useApi } from '@/composables/useApi';
import { useStoreCommon } from '@/stores/storeCommon';
import { useStoreUser } from '@/stores/storeUser';
import { BasicObject } from '@/types/global';
import { UserInfo } from '@/types/user/userTypes';
import { getTimeValue } from '@/utils/date';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export const useUser = () => {
  const router = useRouter();
  const { showToast, showLoading, hideLoading } = useStoreCommon();
  const storeUser = useStoreUser();
  const userInfo = computed(() => storeUser.getUserInfo);
  const userLocationInfo = computed(() => storeUser.getUserLocationInfo);
  const userAddress = computed(() => storeUser.getUserAddress);
  const isLoggedIn = computed(() => storeUser.isLoggedIn);

  const login = async (
    loginInfo: BasicObject,
    loginOptions: BasicObject = {}
  ) => {
    const isValid = Object.values(loginInfo).every((value) => value !== '');

    if (!isValid) {
      showToast('입력 내용을 확인해주세요.');
      return;
    }

    try {
      const options = { data: loginInfo };
      const { data, isSuccess } = await useApi('login', options);

      const { user: userInfo, token } = data;
      if (!isSuccess) throw new Error('로그인 실패');

      // User 정보 및 Token 저장
      storeUser.login({ userInfo, token });

      // Login Options
      const { isModal, callback } = loginOptions;

      // 로그인 성공 Callback 실행
      if (typeof callback === 'function') callback();

      // 메인 페이지 이동
      if (!isModal) router.push('/main');
    } catch (error) {
      console.log('login() - error', error);
      showToast('로그인에 실패했습니다.');
    }
  };

  const logout = async () => {
    const { isSuccess } = await useApi('logout');

    if (isSuccess) {
      storeUser.logout();
      window.location.href = '/login';
    }
  };

  const showLoginToast = () => {
    showToast('로그인 후 이용해주세요.', {
      type: 'secondary',
      position: 'middle',
      buttons: [
        {
          text: '로그인 하러 가기',
          handler: () => {
            window.location.href = '/login';
          },
        },
      ],
    });
  };

  const getRoutine = (date: Date = new Date()) => {
    const routineInfo = userInfo.value?.routineInfo || {};
    const { breakfast, lunch, dinner } = routineInfo;
    const breakfastStart = getTimeValue(breakfast.start);
    const breakfastEnd = getTimeValue(breakfast.end);
    const lunchStart = getTimeValue(lunch.start);
    const lunchEnd = getTimeValue(lunch.end);
    const dinnerStart = getTimeValue(dinner.start);
    const dinnerEnd = getTimeValue(dinner.end);

    const [targetHour, targetMinute] = [date.getHours(), date.getMinutes()];
    const targetValue = getTimeValue(`${targetHour}:${targetMinute}`);

    console.log('getRoutine() - targetValue', targetValue);

    let routineKey = 'etc';
    if (breakfastStart <= targetValue && targetValue <= breakfastEnd) {
      routineKey = 'breakfast';
    } else if (lunchStart <= targetValue && targetValue <= lunchEnd) {
      routineKey = 'lunch';
    } else if (dinnerStart <= targetValue && targetValue <= dinnerEnd) {
      routineKey = 'dinner';
    }

    console.log('getRoutine() - routineKey', routineKey);

    return routineKey;
  };

  const setUserLocationInfo = async () => {
    let isSuccess = false;
    showLoading('내 위치를 설정 중입니다...');

    try {
      await storeUser.setUserLocationInfo();
      isSuccess = true;
    } catch (error: any) {
      console.log('setUserLocationInfo() - error', error);

      const errorCode = error?.code;
      const errorText =
        errorCode === 1
          ? '위치 권한이 차단되어 있습니다.\n위치 권한을 허용해주세요.'
          : '위치를 찾을 수 없습니다.';
      showToast(errorText, { type: 'error' });
    }

    hideLoading();

    return isSuccess;
  };

  const updateStoreUserInfo = async (userInfoData: Partial<UserInfo>) => {
    if (!userInfo.value) return;
    const newUserInfo: UserInfo = { ...userInfo.value, ...userInfoData };
    storeUser.setUserInfo(newUserInfo);
  };

  return {
    userInfo,
    userLocationInfo,
    userAddress,
    isLoggedIn,
    login,
    logout,
    checkLogin: storeUser.checkLogin,
    setUserInfo: storeUser.setUserInfo,
    setUserLocationInfo,
    updateStoreUserInfo,
    getRoutine,
    showLoginToast,
  };
};
