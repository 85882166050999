export const parseHtmlText = (htmlText: string): HTMLElement => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlText, 'text/html');
  return doc.body.firstChild as HTMLElement;
};

export const checkIsMobileSize = (): boolean => {
  const isMobileSize = window.matchMedia('(max-width: 768px)').matches;
  return isMobileSize;
};

export const setFocus = (selector: string, isRetry = false) => {
  const element: HTMLElement | null = document.querySelector(selector);
  if (!element) {
    return setTimeout(() => {
      if (!isRetry) setFocus(selector, true);
    }, 100);
  }
  element.focus();
};

export const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

export const getZIndex = () => {
  const zIndexElements = document.querySelectorAll('[style]');
  const zIndexArray = Array.from(zIndexElements).map((element) => {
    const style = window.getComputedStyle(element);
    const zIndex = style.zIndex;
    return zIndex === 'auto' ? 0 : parseInt(zIndex);
  });

  const maxZIndex = Math.max(...zIndexArray);
  return maxZIndex + 1;
};

export const preloadImage = async (imageUrls: string[]) => {
  const preloadPromises = [];

  for (const imageUrl of imageUrls) {
    preloadPromises.push(
      new Promise<void>((resolve) => {
        const imageTag = new Image();
        imageTag.style.display = 'none';
        // imageTag.style.position = 'absolute';
        // imageTag.style.top = '999999px';

        imageTag.onload = () => {
          document.body.removeChild(imageTag);
          resolve();
        };
        imageTag.src = imageUrl;

        document.body.appendChild(imageTag);
      })
    );
  }

  await Promise.all(preloadPromises);
};
