import axios from 'axios';
import {
  authUrlMap,
  userUrlMap,
  imageUrlMap,
  recordUrlMap,
  commentUrlMap,
  placeUrlMap,
  etcUrlMap,
  notificationUrlMap,
  reportUrlMap,
  pollUrlMap,
  foodMapUrlMap,
} from '@/constants/apiMap';
import { useStoreCommon } from '@/stores/storeCommon';

export const useApi = async (
  apiType: string,
  options: { [key: string]: any } = {}
) => {
  let response = null;
  const { showToast, showLoading, hideLoading } = useStoreCommon();
  const { data: requestData, query, params } = options;

  try {
    showLoading();

    // Auth API
    if (apiType === 'register') {
      response = await register(requestData);
    } else if (apiType === 'login') {
      response = await login(requestData);
    } else if (apiType === 'logout') {
      response = await logout();
    } else if (apiType === 'checkToken') {
      response = await checkToken(requestData);
    } else if (apiType === 'checkEmail') {
      response = await checkEmail(requestData);
    } else if (apiType === 'findEmail') {
      response = await findEmail(requestData);
    } else if (apiType === 'changePassword') {
      response = await changePassword(requestData);
    } else if (apiType === 'forgotPassword') {
      response = await forgotPassword(requestData);
    } else if (apiType === 'resetPassword') {
      response = await resetPassword(requestData);
    }

    // User API
    else if (apiType === 'getUsers') {
      response = await getUsers();
    } else if (apiType === 'searchUsers') {
      response = await searchUsers(query);
    } else if (apiType === 'getUser') {
      response = await getUser();
    } else if (apiType === 'getUserBasic') {
      response = await getUserBasic();
    } else if (apiType === 'getUserBookmarkPlaces') {
      response = await getUserBookmarkPlaces();
    } else if (apiType === 'updateUser') {
      response = await updateUser(requestData);
    } else if (apiType === 'addFriend') {
      response = await addFriend();
    } else if (apiType === 'removeFriend') {
      response = await removeFriend();
    }

    // Notification API
    else if (apiType === 'getMyNotifications') {
      response = await getMyNotifications();
    } else if (apiType === 'createNotification') {
      response = await createNotification();
    } else if (apiType === 'readNotification') {
      response = await readNotification();
    } else if (apiType === 'readAllNotifications') {
      response = await readAllNotifications();
    }

    // Image API
    else if (apiType === 'getImages') {
      response = await getImages(query);
    } else if (apiType === 'getFriendImages') {
      response = await getFriendImages(query);
    } else if (apiType === 'getAroundImages') {
      response = await getAroundImages(query);
    } else if (apiType === 'getMyImages') {
      response = await getMyImages(query);
    } else if (apiType === 'searchImages') {
      response = await searchImages(query);
    } else if (apiType === 'getImage') {
      response = await getImage();
    } else if (apiType === 'createImage') {
      response = await createImage(requestData);
    } else if (apiType === 'updateImage') {
      response = await updateImage(requestData);
    } else if (apiType === 'deleteImage') {
      response = await deleteImage();
    } else if (apiType === 'likeImage') {
      response = await likeImage(requestData);
    }

    // Comment API
    else if (apiType === 'getComments') {
      response = await getComments(query);
    } else if (apiType === 'getTypeComments') {
      response = await getTypeComments(query);
    } else if (apiType === 'getMyComments') {
      response = await getMyComments(query);
    } else if (apiType === 'createComment') {
      response = await createComment(requestData);
    } else if (apiType === 'updateComment') {
      response = await updateComment(requestData);
    } else if (apiType === 'deleteComment') {
      response = await deleteComment(requestData);
    } else if (apiType === 'likeComment') {
      response = await likeComment(requestData);
    }

    // Place API
    else if (apiType === 'getPlaces') {
      response = await getPlaces();
    } else if (apiType === 'getPlace') {
      response = await getPlace();
    } else if (apiType === 'getBookmarkPlaces') {
      response = await getBookmarkPlaces();
    } else if (apiType === 'createPlace') {
      response = await createPlace(requestData);
    } else if (apiType === 'updatePlace') {
      response = await updatePlace(requestData);
    } else if (apiType === 'bookmarkPlace') {
      response = await bookmarkPlace(requestData);
    } else if (apiType === 'unbookmarkPlace') {
      response = await unbookmarkPlace(requestData);
    } else if (apiType === 'deletePlace') {
      response = await deletePlace();
    } else if (apiType === 'searchPlaces') {
      response = await searchPlaces(query);
    } else if (apiType === 'findPlace') {
      response = await findPlace(requestData);
    }

    // Poll API
    else if (apiType === 'getPolls') {
      response = await getPolls();
    } else if (apiType === 'getPoll') {
      response = await getPoll();
    } else if (apiType === 'createPoll') {
      response = await createPoll(requestData);
    } else if (apiType === 'updatePoll') {
      response = await updatePoll(requestData);
    } else if (apiType === 'endPoll') {
      response = await endPoll(requestData);
    } else if (apiType === 'votePoll') {
      response = await votePoll(requestData);
    } else if (apiType === 'unvotePoll') {
      response = await unvotePoll(requestData);
    } else if (apiType === 'deletePoll') {
      response = await deletePoll();
    }

    // Record API
    else if (apiType === 'getRecords') {
      response = await getRecords(query);
    } else if (apiType === 'getRecord') {
      response = await getRecord();
    } else if (apiType === 'getImageRecord') {
      response = await getImageRecord();
    } else if (apiType === 'getMyRecords') {
      response = await getMyRecords(query);
    } else if (apiType === 'getRecordByYearMonth') {
      response = await getRecordByYearMonth(query);
    } else if (apiType === 'createRecord') {
      response = await createRecord(requestData);
    } else if (apiType === 'updateRecord') {
      response = await updateRecord(requestData);
    } else if (apiType === 'deleteRecord') {
      response = await deleteRecord(requestData);
    } else if (apiType === 'updateRecordPlace') {
      response = await updateRecordPlace(requestData);
    } else if (apiType === 'deleteRecordPlace') {
      response = await deleteRecordPlace(requestData);
    } else if (apiType === 'likeRecord') {
      response = await likeRecord(requestData);
    }

    // FoodMap API
    else if (apiType === 'getFoodMaps') {
      response = await getFoodMaps(query);
    } else if (apiType === 'getFoodMap') {
      response = await getFoodMap();
    } else if (apiType === 'getMyFoodMaps') {
      response = await getMyFoodMaps(query);
    } else if (apiType === 'createFoodMap') {
      response = await createFoodMap(requestData);
    } else if (apiType === 'updateFoodMap') {
      response = await updateFoodMap(requestData);
    } else if (apiType === 'deleteFoodMap') {
      response = await deleteFoodMap(requestData);
    }

    // Report API
    else if (apiType === 'getReports') {
      response = await getReports(query);
    } else if (apiType === 'getReport') {
      response = await getReport();
    } else if (apiType === 'getMyReports') {
      response = await getMyReports(query);
    } else if (apiType === 'createReport') {
      response = await createReport(requestData);
    } else if (apiType === 'updateReport') {
      response = await updateReport(requestData);
    } else if (apiType === 'deleteReport') {
      response = await deleteReport(requestData);
    }

    // ETC API
    else if (apiType === 'uploadFile') {
      response = await uploadFile(requestData);
    } else if (apiType === 'getRankingInfo') {
      response = await getRankingInfo();
    }

    // Error
    else {
      throw new Error('Invalid API Type');
    }
  } catch (error: any) {
    console.log('useApi() - error', error);

    let errorMessage = 'API 요청에 실패했습니다.';
    const response = error.response;
    if (response) {
      const { message } = response.data;
      errorMessage = message;
    }
    showToast(errorMessage, { type: 'error' });

    throw errorMessage;
  } finally {
    hideLoading();
  }

  const { status, message, data, isSuccess } = response.data;
  const apiResponse = { response, status, message, data, isSuccess };

  return apiResponse;

  // Auth API Functions
  function register(data?: object) {
    return axios({
      url: authUrlMap.register,
      method: 'POST',
      data,
    });
  }

  function login(data?: object) {
    return axios({
      url: authUrlMap.login,
      method: 'POST',
      data,
    });
  }

  function logout() {
    return axios({
      url: authUrlMap.logout,
      headers: getAuthHeaders(),
      method: 'POST',
    });
  }

  function checkToken(data?: object) {
    return axios({
      url: authUrlMap.checkToken,
      method: 'POST',
      data,
    });
  }

  function checkEmail(data?: object) {
    console.log('data', data);
    return axios({
      url: authUrlMap.checkEmail,
      method: 'POST',
      data,
    });
  }

  function findEmail(data?: object) {
    return axios({
      url: authUrlMap.findEmail,
      method: 'POST',
      data,
    });
  }

  function changePassword(data?: object) {
    return axios({
      url: getApiUrlWithParmas(authUrlMap.changePassword, params),
      method: 'PUT',
      headers: getAuthHeaders(),
      data,
    });
  }

  function forgotPassword(data?: object) {
    return axios({
      url: authUrlMap.forgotPassword,
      method: 'POST',
      data,
    });
  }

  function resetPassword(data?: object) {
    return axios({
      url: getApiUrlWithParmas(authUrlMap.resetPassword, params),
      method: 'PUT',
      data,
    });
  }

  // User API Functions
  function getUsers() {
    return axios({
      url: userUrlMap.getUsers,
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function searchUsers(query?: object) {
    return axios({
      url: userUrlMap.searchUsers,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getUser() {
    return axios({
      url: getApiUrlWithParmas(userUrlMap.getUser, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function getUserBasic() {
    return axios({
      url: getApiUrlWithParmas(userUrlMap.getUserBasic, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function getUserBookmarkPlaces() {
    return axios({
      url: getApiUrlWithParmas(userUrlMap.getUserBookmarkPlaces, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function updateUser(data?: object) {
    return axios({
      url: getApiUrlWithParmas(userUrlMap.updateUser, params),
      headers: getAuthHeaders(),
      data,
      method: 'PUT',
    });
  }

  function addFriend(data?: object) {
    return axios({
      url: getApiUrlWithParmas(userUrlMap.addFriend, params),
      headers: getAuthHeaders(),
      data,
      method: 'POST',
    });
  }

  function removeFriend(data?: object) {
    return axios({
      url: getApiUrlWithParmas(userUrlMap.removeFriend, params),
      headers: getAuthHeaders(),
      data,
      method: 'DELETE',
    });
  }

  function getMyNotifications(query?: object) {
    return axios({
      url: getApiUrlWithParmas(notificationUrlMap.getMyNotifications, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function createNotification(data?: object) {
    return axios({
      url: notificationUrlMap.createNotification,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function readAllNotifications(data?: object) {
    return axios({
      url: getApiUrlWithParmas(notificationUrlMap.readAllNotifications, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function readNotification(data?: object) {
    return axios({
      url: getApiUrlWithParmas(notificationUrlMap.readNotification, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  // Image API Functions
  function getImages(query?: object) {
    return axios({
      url: imageUrlMap.getImages,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getFriendImages(query?: object) {
    return axios({
      url: imageUrlMap.getFriendImages,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getAroundImages(query?: object) {
    return axios({
      url: imageUrlMap.getAroundImages,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getMyImages(query?: object) {
    return axios({
      url: getApiUrlWithParmas(imageUrlMap.getMyImages, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function searchImages(query: string) {
    return axios({
      url: getApiUrlWithParmas(imageUrlMap.searchImages, { query }),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function getImage() {
    return axios({
      url: getApiUrlWithParmas(imageUrlMap.getImage, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function createImage(data?: object) {
    return axios({
      url: imageUrlMap.createImage,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function updateImage(data?: object) {
    return axios({
      url: getApiUrlWithParmas(imageUrlMap.updateImage, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function deleteImage(data?: object) {
    return axios({
      url: getApiUrlWithParmas(imageUrlMap.deleteImage, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  function likeImage(data?: object) {
    return axios({
      url: getApiUrlWithParmas(imageUrlMap.likeImage, params),
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function getComments(query?: object) {
    return axios({
      url: commentUrlMap.getComments,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }
  function getTypeComments(query?: object) {
    return axios({
      url: getApiUrlWithParmas(commentUrlMap.getComments, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getMyComments(query?: object) {
    return axios({
      url: getApiUrlWithParmas(commentUrlMap.getMyComments, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function createComment(data?: object) {
    return axios({
      url: getApiUrlWithParmas(commentUrlMap.createComment, params),
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function updateComment(data?: object) {
    return axios({
      url: getApiUrlWithParmas(commentUrlMap.updateComment, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function deleteComment(data?: object) {
    return axios({
      url: getApiUrlWithParmas(commentUrlMap.deleteComment, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  function likeComment(data?: object) {
    return axios({
      url: getApiUrlWithParmas(commentUrlMap.likeComment, params),
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  // Place API Functions
  function getPlaces() {
    return axios({
      url: placeUrlMap.getPlaces,
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function getPlace() {
    return axios({
      url: getApiUrlWithParmas(placeUrlMap.getPlace, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function getBookmarkPlaces() {
    return axios({
      url: getApiUrlWithParmas(placeUrlMap.getBookmarkPlaces, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function createPlace(data?: object) {
    return axios({
      url: placeUrlMap.createPlace,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function updatePlace(data?: object) {
    return axios({
      url: getApiUrlWithParmas(placeUrlMap.updatePlace, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function bookmarkPlace(data?: object) {
    return axios({
      url: getApiUrlWithParmas(placeUrlMap.bookmarkPlace, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function unbookmarkPlace(data?: object) {
    return axios({
      url: getApiUrlWithParmas(placeUrlMap.unbookmarkPlace, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  function deletePlace() {
    return axios({
      url: getApiUrlWithParmas(placeUrlMap.deletePlace, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
    });
  }

  function findPlace(data?: object) {
    return axios({
      url: placeUrlMap.findPlace,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function searchPlaces(query: object) {
    return axios({
      url: placeUrlMap.searchPlaces,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  // Poll API Functions
  function getPolls() {
    return axios({
      url: pollUrlMap.getPolls,
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function getPoll() {
    return axios({
      url: getApiUrlWithParmas(pollUrlMap.getPoll, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function createPoll(data?: object) {
    return axios({
      url: pollUrlMap.createPoll,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function updatePoll(data?: object) {
    return axios({
      url: getApiUrlWithParmas(pollUrlMap.updatePoll, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function endPoll(data?: object) {
    return axios({
      url: getApiUrlWithParmas(pollUrlMap.endPoll, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function votePoll(data?: object) {
    return axios({
      url: getApiUrlWithParmas(pollUrlMap.votePoll, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function unvotePoll(data?: object) {
    return axios({
      url: getApiUrlWithParmas(pollUrlMap.unvotePoll, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  function deletePoll() {
    return axios({
      url: getApiUrlWithParmas(pollUrlMap.deletePoll, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
    });
  }

  // Record API Functions
  function getRecords(query: object) {
    return axios({
      url: recordUrlMap.getRecords,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getMyRecords(query: object) {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.getMyRecords, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getRecordByYearMonth(query: object) {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.getRecordByYearMonth, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getRecord() {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.getRecord, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function getImageRecord() {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.getImageRecord, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function createRecord(data?: object) {
    return axios({
      url: recordUrlMap.createRecord,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function updateRecord(data?: object) {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.updateRecord, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function updateRecordPlace(data?: object) {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.updateRecordPlace, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function deleteRecordPlace(data?: object) {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.deleteRecordPlace, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  function deleteRecord(data?: object) {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.deleteRecord, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  function likeRecord(data?: object) {
    return axios({
      url: getApiUrlWithParmas(recordUrlMap.likeRecord, params),
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  // FoodMap API Functions
  function getFoodMaps(query: object) {
    return axios({
      url: foodMapUrlMap.getFoodMaps,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getMyFoodMaps(query: object) {
    return axios({
      url: getApiUrlWithParmas(foodMapUrlMap.getMyFoodMaps, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getFoodMap() {
    return axios({
      url: getApiUrlWithParmas(foodMapUrlMap.getFoodMap, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function createFoodMap(data?: object) {
    return axios({
      url: foodMapUrlMap.createFoodMap,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function updateFoodMap(data?: object) {
    return axios({
      url: getApiUrlWithParmas(foodMapUrlMap.updateFoodMap, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function deleteFoodMap(data?: object) {
    return axios({
      url: getApiUrlWithParmas(foodMapUrlMap.deleteFoodMap, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  // Report API Functions
  function getReports(query: object) {
    return axios({
      url: reportUrlMap.getReports,
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getMyReports(query: object) {
    return axios({
      url: getApiUrlWithParmas(reportUrlMap.getMyReports, params),
      headers: getAuthHeaders(),
      method: 'GET',
      params: query,
    });
  }

  function getReport() {
    return axios({
      url: getApiUrlWithParmas(reportUrlMap.getReport, params),
      headers: getAuthHeaders(),
      method: 'GET',
    });
  }

  function createReport(data?: object) {
    return axios({
      url: reportUrlMap.createReport,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function updateReport(data?: object) {
    return axios({
      url: getApiUrlWithParmas(reportUrlMap.updateReport, params),
      headers: getAuthHeaders(),
      method: 'PUT',
      data,
    });
  }

  function deleteReport(data?: object) {
    return axios({
      url: getApiUrlWithParmas(reportUrlMap.deleteReport, params),
      headers: getAuthHeaders(),
      method: 'DELETE',
      data,
    });
  }

  // ETC API Functions
  function uploadFile(data?: object) {
    return axios({
      url: etcUrlMap.uploadFile,
      headers: getAuthHeaders(),
      method: 'POST',
      data,
    });
  }

  function getRankingInfo(data?: object) {
    return axios({
      url: etcUrlMap.getRankingInfo,
      headers: getAuthHeaders(),
      method: 'GET',
      data,
    });
  }

  // API Util Functions
  function getAuthHeaders() {
    return {
      // REVIEW: 추후 토큰 가져오는 방법 확인
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
  }

  function getApiUrlWithParmas(
    url: string,
    params: { [key: string]: string } = {}
  ) {
    const keys = Object.keys(params);
    const urlWithParams = keys.reduce((acc, key) => {
      return acc.replace(`:${key}`, params[key]);
    }, url);

    return urlWithParams;
  }
};
