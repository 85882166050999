import { getISOString } from '@/utils/date';
import { getRandomId } from '@/utils/common';
import {
  DatePickerOptions,
  ImageInputOptions,
  LoadingOptions,
  ModalOptions,
  ToastOptions,
} from '@/types/common/modalTypes';
import { BasicObject } from '@/types/global';
import { defineStore } from 'pinia';

interface StoreCommonState {
  refreshKey: number;
  loadings: LoadingOptions[];
  alertInfo: ModalOptions;
  confirmInfo: ModalOptions;
  toastInfo: ToastOptions;
  datePickerInfo: DatePickerOptions;
  imageInputInfo: BasicObject;
}

export const useStoreCommon = defineStore('storeCommon', {
  state: (): StoreCommonState => {
    return {
      refreshKey: 0,

      loadings: [],

      alertInfo: {
        title: '알림',
        message: '',
        isShow: false,
      },

      confirmInfo: {
        title: '확인',
        message: '',
        isShow: false,
        resolve: null,
      },

      toastInfo: {
        type: undefined,
        message: '',
        duration: null,
        position: 'bottom',
        buttons: [],
        isBackdrop: false,
        isShow: false,
      },

      datePickerInfo: {
        initialDate: '',
        presentation: 'date-time',
        isTime: false,
        isShow: false,
        resolve: null,
      },

      imageInputInfo: {
        encoding: null,
        isShow: false,
        resolve: null,
      },
    };
  },

  actions: {
    refresh() {
      this.refreshKey += 1;
    },

    showAlert(message: string, options: ModalOptions = {}) {
      const alertInfo = {
        title: options?.title || '알림',
        message,
        isShow: true,
      };

      this.alertInfo = alertInfo;
    },

    hideAlert() {
      this.alertInfo = {
        title: '알림',
        message: '',
        isShow: false,
      };
    },

    showConfirm(message: string, options: ModalOptions = {}) {
      return new Promise((resolve) => {
        const confirmInfo = {
          title: options?.title || '확인',
          message,
          isShow: true,
          resolve,
        };

        this.confirmInfo = confirmInfo;
      });
    },

    hideConfirm() {
      this.confirmInfo = {
        title: '알림',
        message: '',
        isShow: false,
        resolve: null,
      };
    },

    showToast(message: string, options: ToastOptions = {}) {
      const toastInfo = {
        type: options?.type,
        message,
        duration: options?.duration,
        position: options?.position,
        buttons: options?.buttons,
        isBackdrop: options?.isBackdrop,
        isShow: true,
      };

      this.toastInfo = toastInfo;
    },

    hideToast() {
      this.toastInfo = {
        type: undefined,
        message: '',
        duration: 2000,
        position: 'bottom',
        buttons: [],
        isBackdrop: false,
        isShow: false,
      };
    },

    showLoading(message?: string, options: LoadingOptions = {}) {
      const loadingInfo = {
        id: getRandomId(),
        message,
        duration: options?.duration,
        isShow: true,
      };

      this.loadings.push(loadingInfo);
    },

    hideLoading() {
      this.loadings.pop();
    },

    showDatePicker(
      dateString: string,
      options: DatePickerOptions = {}
    ): Promise<string> {
      return new Promise((resolve) => {
        const datePickerInfo = {
          initialDate: getISOString(new Date(dateString)),
          presentation: options?.presentation || 'date-time',
          isTime: options?.isTime ?? false,
          isShow: true,
          resolve,
        };

        this.datePickerInfo = datePickerInfo;
      });
    },

    hideDatePicker() {
      this.datePickerInfo = {
        initialDate: '',
        presentation: 'date-time',
        isTime: false,
        isShow: false,
        resolve: null,
      };
    },

    showImageInput(options?: ImageInputOptions): Promise<BasicObject> {
      return new Promise((resolve) => {
        this.imageInputInfo = {
          encoding: options?.encoding || null,
          isShow: true,
          resolve,
        };
      });
    },

    hideImageInput() {
      this.imageInputInfo = {
        encoding: null,
        isShow: false,
        resolve: null,
      };
    },

    showDeveloping() {
      this.showToast('해당 기능은 개발중입니다.');
    },
  },

  getters: {},
});
