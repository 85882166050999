import { useApi } from '@/composables/useApi';
import { useStoreUser } from '@/stores/storeUser';
import { Notification } from '@/types/user/userTypes';
import { ComputedRef, computed } from 'vue';
import { useUser } from './useUser';

export const useNotification = () => {
  const storeUser = useStoreUser();
  const { userInfo } = useUser();

  const notifications: ComputedRef<Notification[]> = computed(
    () => storeUser.getNotifications
  );

  const setNotifications = async () => {
    console.log('setNotifications() - userInfo.value', userInfo.value);
    if (!userInfo.value?._id) return;

    const { data } = await useApi('getMyNotifications', {
      params: { _userId: userInfo.value?._id },
    });
    console.log('setNotifications() - data', data);
    storeUser.setNotifications(data.notifications);
  };

  return {
    notifications,
    setNotifications,
  };
};
