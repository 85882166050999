import { BasicObject } from '@/types/global';

// 유효한 Date 객체인지 확인하는 함수
export const checkInvalidDate = (date: Date) => {
  return isNaN(date.getTime());
};

// Date 객체를 한국 날짜 형식으로 변환하는 함수
export const formatKoreanDate = (date: Date, options: BasicObject = {}) => {
  if (checkInvalidDate(date)) return '';

  const { isTime, isSecond } = options;

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const dateString = `${year}년 ${month}월 ${day}일`;
  const timeString =
    `${date.getHours()}시 ${date.getMinutes()}분` +
    (isSecond ? ` ${date.getSeconds()}초` : '');

  if (isTime) return `${dateString} ${timeString}`;
  return dateString;
};

// 지역에 맞게 날짜 형식을 ISO 형식으로 변환하는 함수
export const getISOString = (date: Date = new Date()) => {
  return new Date(
    date.setHours(date.getHours() - date.getTimezoneOffset() / 60)
  ).toISOString();
};

// 입력된 시간을 숫자 값으로 변환하는 함수
export const getTimeValue = (time: string) => {
  const [hour, minute] = time.split(':').map((value) => Number(value));
  return hour * 60 + minute;
};

// 두 DateString / Date 객체가 같은 날짜&시간인지 확인하는 함수
export const checkSameDate = (date1: string | Date, date2: string | Date) => {
  const dateObj1 = typeof date1 === 'string' ? new Date(date1) : date1;
  const dateObj2 = typeof date2 === 'string' ? new Date(date2) : date2;
  return dateObj1.getTime() === dateObj2.getTime();
};

// 달력에서 해당 월의 날짜 Table의 배열을 반환
export const getCalendarDates = (date: Date, options: BasicObject = {}) => {
  const { isShowOtherMonthDate } = options;

  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const firstDayIndex = firstDay.getDay();
  const lastDayIndex = lastDay.getDay();
  const dates = [];

  // 이전 월의 날짜
  for (let i = 0; i < firstDayIndex; i++) {
    if (isShowOtherMonthDate)
      dates.push(new Date(date.getFullYear(), date.getMonth(), -i));
    else dates.push(null);
  }

  // 해당 월의 날짜
  for (let i = 1; i <= lastDay.getDate(); i++) {
    dates.push(new Date(date.getFullYear(), date.getMonth(), i));
  }

  // 다음 월의 날짜
  for (let i = lastDayIndex + 1; i < 7; i++) {
    if (isShowOtherMonthDate)
      dates.push(new Date(date.getFullYear(), date.getMonth() + 1, i));
    else dates.push(null);
  }

  return dates;
};
