import { useApi } from '@/composables/useApi';
import { useMap } from '@/composables/useMap';
import { MapLocationInfo } from '@/types/place/mapTypes';
import { Notification, UserInfo } from '@/types/user/userTypes';
import { defineStore } from 'pinia';

interface StoreUserState {
  userInfo: UserInfo | null;
  token: string | null;
  userLocationInfo: MapLocationInfo | null;
  userAddress: string;
  notifications: Notification[];
  isLoginChecked: boolean;
}

export const useStoreUser = defineStore('storeUser', {
  state: (): StoreUserState => {
    return {
      userInfo: null,
      token: null,
      userLocationInfo: null,
      userAddress: '',
      notifications: [],
      isLoginChecked: false,
    };
  },

  actions: {
    setUserInfo(userInfo: UserInfo) {
      this.userInfo = userInfo;
    },

    async setUserLocationInfo() {
      try {
        const { getMyLocation, getAddress } = useMap();

        this.userLocationInfo = await getMyLocation();
        this.userAddress = await getAddress(this.userLocationInfo);
        return true;
      } catch (error) {
        console.log('setUserLocationInfo() - error', error);
        throw error;
      }
    },

    setNotifications(notifications: Notification[]) {
      this.notifications = notifications;
    },

    async checkLogin() {
      const token = localStorage.getItem('token') || '';
      const options = { data: { token } };
      const { data } = await useApi('checkToken', options);
      const { user: userInfo, isValidToken } = data;

      if (isValidToken) this.login({ userInfo, token });
      else this.logout();

      this.isLoginChecked = true;
    },

    login({ userInfo, token }: { userInfo: UserInfo; token: string }) {
      this.userInfo = userInfo;
      this.token = token;
      localStorage.setItem('token', token);
    },

    logout() {
      this.userInfo = null;
      this.token = null;
      localStorage.removeItem('token');
    },
  },

  getters: {
    getUserInfo(state) {
      return state.userInfo;
    },

    getNotifications(state) {
      return state.notifications as Notification[];
    },

    getUserLocationInfo(state) {
      return state.userLocationInfo;
    },

    getUserAddress(state) {
      return state.userAddress;
    },

    isLoggedIn(state) {
      return !!state.token;
    },
  },
});
