export const INITIAL_RECORD = {
  title: '',
  description: '',
  images: [],
  placeInfo: null,
  rating: 0,
  date: '',
  likeInfo: {
    likes: [],
    dislikes: [],
  },
  likeCount: 0,
  dislikeCount: 0,
  viewUsers: [],
  viewCount: 0,
  gathering: undefined,
  isPrivate: false,
  creator: null,
};
