import { BasicObject } from '@/types/global';
import { ImageInfo } from '@/types/image/imageTypes';
import { MapLocationInfo, MapMarkerOptions } from '@/types/place/mapTypes';
import { PlaceInfo } from '@/types/place/placeTypes';
import { defineStore } from 'pinia';

interface StoreSubPage {
  addPlaceInfo: SubPageInfoDefault;
  addRecordInfo: SubPageInfoDefault;
  userModalInfo: SubPageInfoDefault;
  userSearchInfo: SubPageInfoDefault;
  userProfileInfo: SubPageInfoDefault;
  mapModalInfo: SubPageMapModalInfo;
  placeSearchInfo: SubPagePlaceSearchInfo;
  placeInventoryInfo: BasicObject;
  imageInventoryInfo: BasicObject;
  imageViewerInfo: BasicObject;
  imageCropInfo: BasicObject;
  appInfo: BasicObject;
}

interface SubPageInfoDefault {
  data?: BasicObject | null;
  isShow: boolean;
  resolve?: ((value: any) => void) | null;
}

interface SubPageMapModalInfo extends SubPageInfoDefault {
  title: string;
  locationInfo: MapLocationInfo | null;
  markers?: MapMarkerOptions[];
}

interface MapModalOptions {
  title?: string;
  locationInfo: MapLocationInfo | null;
  markers?: MapMarkerOptions[];
}

interface SubPagePlaceSearchInfo extends SubPageInfoDefault {
  searchTerm?: string;
}

export const useStoreSubPage = defineStore('storeSubPage', {
  state: (): StoreSubPage => {
    return {
      addPlaceInfo: {
        data: {},
        isShow: false,
        resolve: null,
      },

      addRecordInfo: {
        data: {},
        isShow: false,
        resolve: null,
      },

      userProfileInfo: {
        isShow: false,
      },

      userSearchInfo: {
        isShow: false,
      },

      userModalInfo: {
        data: null,
        isShow: false,
      },

      mapModalInfo: {
        title: '',
        locationInfo: null,
        markers: [],
        isShow: false,
        resolve: null,
      },

      placeSearchInfo: {
        data: {},
        isShow: false,
        resolve: null,
      },

      placeInventoryInfo: {
        isShow: false,
        resolve: null,
      },

      imageInventoryInfo: {
        isShow: false,
        resolve: null,
      },

      imageViewerInfo: {
        imageInfo: null,
        isShow: false,
        resolve: null,
      },

      imageCropInfo: {
        imageData: null,
        isShow: false,
        resolve: null,
      },

      appInfo: {
        isShow: false,
      },
    };
  },

  actions: {
    showAddPlace(data: object) {
      this.addPlaceInfo = {
        data,
        isShow: true,
      };
    },

    hideAddPlace() {
      this.addPlaceInfo = {
        data: {},
        isShow: false,
      };
    },

    showAddRecord(data?: object) {
      return new Promise((resolve) => {
        this.addRecordInfo = {
          data,
          resolve,
          isShow: true,
        };
      });
    },

    hideAddRecord() {
      this.addRecordInfo = {
        data: {},
        isShow: false,
      };
    },

    showUserProfile() {
      this.userProfileInfo = {
        isShow: true,
      };
    },

    hideUserProfile() {
      this.userProfileInfo = { isShow: false };
    },

    showUserSearch() {
      return new Promise((resolve) => {
        this.userSearchInfo = { isShow: true, resolve };
      });
    },

    hideUserSearch() {
      this.userSearchInfo = { isShow: false };
    },

    showUserModal(data?: object) {
      this.userModalInfo = {
        data,
        isShow: true,
      };
    },

    hideUserModal() {
      this.userModalInfo = {
        data: null,
        isShow: false,
      };
    },

    showMapModal({ title = '', locationInfo, markers = [] }: MapModalOptions) {
      return new Promise((resolve) => {
        this.mapModalInfo = {
          title,
          locationInfo,
          markers,
          isShow: true,
          resolve,
        };
      });
    },

    hideMapModal() {
      this.mapModalInfo = {
        title: '',
        locationInfo: null,
        markers: [],
        resolve: null,
        isShow: false,
      };
    },

    showPlaceSearch({ searchTerm = '' } = {}) {
      return new Promise<BasicObject>((resolve) => {
        this.placeSearchInfo = {
          searchTerm,
          resolve,
          isShow: true,
        };
      });
    },

    hidePlaceSearch() {
      this.placeSearchInfo = { searchTerm: '', resolve: null, isShow: false };
    },

    showPlaceInventory() {
      return new Promise<PlaceInfo>((resolve) => {
        this.placeInventoryInfo = {
          isShow: true,
          resolve,
        };
      });
    },

    hidePlaceInventory() {
      this.placeInventoryInfo = {
        isShow: false,
        resolve: null,
      };
    },

    showImageInventory() {
      return new Promise<ImageInfo>((resolve) => {
        this.imageInventoryInfo = {
          isShow: true,
          resolve,
        };
      });
    },

    hideImageInventory() {
      this.imageInventoryInfo = {
        isShow: false,
        resolve: null,
      };
    },

    showImageViewer(imageInfo: BasicObject) {
      return new Promise<string>((resolve) => {
        this.imageViewerInfo = {
          imageInfo,
          isShow: true,
          resolve,
        };
      });
    },

    hideImageViewer() {
      this.imageViewerInfo = {
        imageInfo: null,
        isShow: false,
        resolve: null,
      };
    },

    showImageCrop(imageData: Blob | string) {
      return new Promise<Blob>((resolve) => {
        this.imageCropInfo = {
          imageData,
          isShow: true,
          resolve,
        };
      });
    },

    hideImageCrop() {
      this.imageCropInfo = {
        imageData: null,
        isShow: false,
        resolve: null,
      };
    },

    showAppInfo() {
      this.appInfo = {
        isShow: true,
      };
    },

    hideAppInfo() {
      this.appInfo = {
        isShow: false,
      };
    },
  },

  getters: {},
});
