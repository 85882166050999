import { createApp } from 'vue';
import App from './App.vue';
import pinia from './stores';
import router from './router';
import { IonicVue } from '@ionic/vue';
import { createNaverMap } from 'vue3-naver-maps';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// Animate.css
import 'animate.css';

// register Swiper custom elements
register();

const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(router)
  .use(createNaverMap, {
    clientId: process.env.VUE_APP_NAVER_MAP_CLIENT_ID,
  });

router.isReady().then(() => {
  app.mount('#app');
});
