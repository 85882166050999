import { BasicObject } from '@/types/global';

export const ROUTINE_TEXT_INFO: BasicObject = {
  breakfast: '아침',
  lunch: '점심',
  dinner: '저녁',
  snack: '간식',
  etc: '식사',
};

export const REPORT_TYPE_TEXT_INFO: BasicObject = {
  User: '유저',
  Image: '이미지',
  ImageComment: '이미지 댓글',
  Record: '기록',
  RecordComment: '기록 댓글',
  ChatRoom: '채팅방',
  ChatMessage: '채팅 메시지',
  Bug: '버그',
  Suggestion: '건의사항',
  Other: '기타',
};
