// Auth API
export const authUrlMap = {
  register: '/api/v1/auth/register',
  login: '/api/v1/auth/login',
  logout: '/api/v1/auth/logout',
  logoutNaver: '/api/v1/auth/logout/naver',
  checkPassword: '/api/v1/auth/checkPassword',
  changePassword: '/api/v1/auth/changePassword/:_id',
  checkToken: '/api/v1/auth/checkToken',
  checkEmail: '/api/v1/auth/checkEmail',
  findEmail: '/api/v1/auth/findEmail',
  forgotPassword: '/api/v1/auth/forgotPassword',
  resetPassword: '/api/v1/auth/resetPassword/:resetPasswordToken',
  unregister: '/api/v1/auth/unregister',
};

// User API
export const userUrlMap = {
  getUsers: '/api/v1/users',
  searchUsers: '/api/v1/users/search',
  getUser: '/api/v1/users/:_id',
  getUserBasic: '/api/v1/users/:_id/basic',
  getUserBookmarkPlaces: '/api/v1/users/:_id/bookmark-places',
  updateUser: '/api/v1/users/:_id',
  addFriend: '/api/v1/users/friends/:_friendId',
  removeFriend: '/api/v1/users/friends/:_friendId',

  // NOTE: 사용하지 않음으로 주석 처리
  // deleteUser: '/api/v1/users/:_id',
  // searchUsers: '/api/v1/users/search',
};

// Notification API
export const notificationUrlMap = {
  getNotifications: '/api/v1/notifications',
  getMyNotifications: '/api/v1/notifications/users/:_userId',
  getNotification: '/api/v1/notifications/:_id',
  createNotification: '/api/v1/notifications',
  updateNotification: '/api/v1/notifications/:_id',
  deleteNotification: '/api/v1/notifications/:_id',
  readAllNotifications: '/api/v1/notifications/read/all',
  readNotification: '/api/v1/notifications/:_id/read',
};

// Image API
export const imageUrlMap = {
  getImages: '/api/v1/images',
  getFriendImages: '/api/v1/images/friends',
  getAroundImages: '/api/v1/images/around',
  getMyImages: '/api/v1/images/users/:_userId',
  getImage: '/api/v1/images/:_id',
  searchImages: '/api/v1/images/search',
  createImage: '/api/v1/images',
  updateImage: '/api/v1/images/:_id',
  deleteImage: '/api/v1/images/:_id',
  likeImage: '/api/v1/images/:_id/like',
};

// Record API
export const recordUrlMap = {
  getRecords: '/api/v1/records',
  getMyRecords: '/api/v1/records/users/:_userId',
  getRecordByYearMonth: '/api/v1/records/users/:_userId/year-month/:yearMonth',
  getRecord: '/api/v1/records/:_id',
  getImageRecord: '/api/v1/records/images/:_imageId',
  createRecord: '/api/v1/records',
  updateRecord: '/api/v1/records/:_id',
  updateRecordPlace: '/api/v1/records/:_id/place',
  deleteRecordPlace: '/api/v1/records/:_id/place',
  likeRecord: '/api/v1/records/:_id/like',
  deleteRecord: '/api/v1/records/:_id',
};

// Comment API
export const commentUrlMap = {
  getComments: '/api/v1/comments',
  getTypeComments: '/api/v1/comments/:type',
  getMyComments: '/api/v1/comments/users/:_userId',
  getComment: '/api/v1/comments/:_id',
  createComment: '/api/v1/comments',
  updateComment: '/api/v1/comments/:_id',
  deleteComment: '/api/v1/comments/:_id',
  likeComment: '/api/v1/comments/:_id/like',
};

// Place API
export const placeUrlMap = {
  getPlaces: '/api/v1/places',
  getPlace: '/api/v1/places/:_id',
  getBookmarkPlaces: '/api/v1/places/bookmarks/users/:_userId',
  findPlace: '/api/v1/places/find',
  searchPlaces: '/api/v1/places/search',
  createPlace: '/api/v1/places',
  updatePlace: '/api/v1/places/:_id',
  bookmarkPlace: '/api/v1/places/:_id/bookmark',
  unbookmarkPlace: '/api/v1/places/:_id/unbookmark',
  deletePlace: '/api/v1/places/:_id',
};

// Food Map API
export const foodMapUrlMap = {
  getFoodMaps: '/api/v1/food-maps',
  getMyFoodMaps: '/api/v1/food-maps/users/:_userId',
  getFoodMap: '/api/v1/food-maps/:_id',
  createFoodMap: '/api/v1/food-maps',
  updateFoodMap: '/api/v1/food-maps/:_id',
  bookmarkFoodMap: '/api/v1/food-maps/:_id/bookmark',
  unbookmarkFoodMap: '/api/v1/food-maps/:_id/unbookmark',
  deleteFoodMap: '/api/v1/food-maps/:_id',
};

// Poll API
export const pollUrlMap = {
  getPolls: '/api/v1/polls',
  getPoll: '/api/v1/polls/:_id',
  createPoll: '/api/v1/polls',
  updatePoll: '/api/v1/polls/:_id',
  endPoll: '/api/v1/polls/:_id/end',
  votePoll: '/api/v1/polls/:_id/vote',
  unvotePoll: '/api/v1/polls/:_id/unvote',
  deletePoll: '/api/v1/polls/:_id',
};

// Report API
export const reportUrlMap = {
  getReports: '/api/v1/reports',
  getMyReports: '/api/v1/reports/users/:_userId',
  getReport: '/api/v1/reports/:_id',
  createReport: '/api/v1/reports',
  updateReport: '/api/v1/reports/:_id',
  deleteReport: '/api/v1/reports/:_id',
};

// etc API
export const etcUrlMap = {
  uploadFile: '/api/v1/uploads/uploadFile',
  getRankingInfo: '/api/v1/etc/ranking',
};
