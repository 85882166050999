import { BasicObject } from '@/types/global';
import { Ref, ref } from 'vue';

export const useModal = () => {
  const title = ref<any>('');
  const data = ref<any>(null);
  const isShow: Ref<boolean> = ref(false);

  const setTitle = (modalData: any) => {
    title.value = modalData;
  };

  const setData = (modalData: any) => {
    data.value = modalData;
  };

  const show = (modalOption: BasicObject = {}) => {
    const { title = '', data } = modalOption;
    setTitle(title);
    setData(data);

    isShow.value = true;
  };

  const hide = () => {
    data.value = null;
    isShow.value = false;
  };

  return {
    title,
    data,
    isShow,
    setTitle,
    setData,
    show,
    hide,
  };
};
