export const PUBLIC_PATH = '';
export const PUBLIC_IMAGE_PATH = `${PUBLIC_PATH}/assets/images`;

export const DEFAULT_IMAGE_URL = `${PUBLIC_IMAGE_PATH}/defaults/default_image.svg`;
export const DEFAULT_AVATAR_URL = `${PUBLIC_IMAGE_PATH}/defaults/default_avatar.svg`;
export const DEFAULT_POLL_URL = `${PUBLIC_IMAGE_PATH}/defaults/default_poll.jpg`;
export const DEFAULT_FOOD_MAP_URL = `${PUBLIC_IMAGE_PATH}/defaults/default_poll.jpg`;

export const DEFAULT_MAP_LOCATION = {
  lat: 37.3595704,
  lng: 127.105399,
};

export const DEFAULT_MAP_OPTIONS = {
  zoom: 16,
  minZoom: 6,
  maxZoom: 20,
  zoomControl: false,
  zoomControlOptions: {
    style: 1,
    position: 3,
  },
  center: DEFAULT_MAP_LOCATION,
};
