import { BasicObject } from '@/types/global';
import { MapInfoWindowOptions, MapLocationInfo } from '@/types/place/mapTypes';
import { parseHtmlText } from '@/utils/ui';
import { useUser } from './useUser';
import { useApi } from './useApi';
import { useStoreCommon } from '@/stores/storeCommon';
import { PlaceInfo } from '@/types/place/placeTypes';

export const useMap = () => {
  // 카카오 지도 API
  const kakaoPlace = new window.kakao.maps.services.Places();
  const { showToast } = useStoreCommon();
  const { userInfo, showLoginToast, updateStoreUserInfo } = useUser();

  // 같은 장소인지 확인하는 함수
  const checkSamePlace = (place1: BasicObject, place2: BasicObject) => {
    const {
      name: name1,
      locationInfo: { lat: lat1, lng: lng1 },
    } = place1;
    const {
      name: name2,
      locationInfo: { lat: lat2, lng: lng2 },
    } = place2;
    return name1 === name2 && lat1 === lat2 && lng1 === lng2;
  };

  // Bookmark 된 장소인지 확인
  const checkBookmarkedPlace = (placeInfo: Partial<PlaceInfo>) => {
    const userBookmarkPlaces = userInfo.value?.bookmarkPlaces as string[];
    const isBookmarked = userBookmarkPlaces.includes(placeInfo._id as string);
    return isBookmarked;
  };

  // 장소 정보를 담은 객체를 받아서, 찜하기를 수행하는 함수
  const bookmarkPlace = async ({
    type = 'bookmark',
    placeInfo = {},
    target,
  }: {
    type: 'bookmark' | 'unbookmark';
    placeInfo: Partial<PlaceInfo>;
    target?: HTMLElement;
  }) => {
    const bookmarkText = type === 'bookmark' ? '찜하기' : '찜하기 취소';
    const bookmarkChangeText = type === 'bookmark' ? '찜하기 취소' : '찜하기';

    try {
      // 로그인 여부 확인
      if (!userInfo.value) return showLoginToast();

      // 장소에 _id 가 있는지 확인하여, 없으면 서버에 장소 정보 조회/생성
      if (!placeInfo._id) {
        const {
          data: { place },
        } = await useApi('findPlace', { data: placeInfo });
        placeInfo._id = place._id;
      }

      // 찜하기/찜하기 취소 요청
      const apiRequestName = `${type}Place`;
      const {
        isSuccess,
        data: { bookmarkers, bookmarkPlaces },
      } = await useApi(apiRequestName, {
        params: { _id: placeInfo._id },
      });

      if (isSuccess) {
        placeInfo.bookmarkers = bookmarkers;
        updateStoreUserInfo({ bookmarkPlaces });
        if (target) target.innerHTML = `${bookmarkChangeText}`;
        showToast(`${bookmarkText}에 성공하였습니다.`, {
          type: 'success',
        });
      } else showToast(`${bookmarkText}에 실패하였습니다.`, { type: 'error' });
    } catch (error) {
      console.log('bookmarkPlace() - error', error);

      showToast(`${bookmarkText}에 실패하였습니다.`, { type: 'error' });
    }
  };

  // 내 위치의 위도, 경도를 반환하는 함수
  const getMyLocation = async () => {
    return new Promise<MapLocationInfo>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude,
            lng = position.coords.longitude;
          const currentLocation = { lat, lng };

          resolve(currentLocation);
        },
        (positionError) => {
          console.log('getMyLocation() - positionError', positionError);
          reject(positionError);
        }
      );
    });
  };

  // 현재 위치의 {lat, lng}으로 지역 정보를 반환하는 함수
  const getAreaInfo = (locationInfo: MapLocationInfo) => {
    return new Promise<BasicObject>((resolve, reject) => {
      naver.maps.Service.reverseGeocode(
        {
          coords: parseLatLng(locationInfo),
        },
        (status, response) => {
          if (status !== naver.maps.Service.Status.OK) {
            reject(new Error('지역 검색에 실패하였습니다.'));
          }

          const results = response.v2.results;
          const region = results[0]?.region || {};
          const address = response.v2.address;

          resolve({ address, region });
        }
      );
    });
  };

  // 현재 위치의 {lat, lng}으로 주소를 반환하는 함수
  const getAddress = async (locationInfo: MapLocationInfo) => {
    const { address: addressInfo } = await getAreaInfo(locationInfo);
    const address = addressInfo?.jibunAddress || addressInfo.roadAddress || '';
    return address;
  };

  // 지도에 표시할 Info Window 객체를 반환하는 함수
  const getInfoWindow = (
    infoWindowInfo: MapInfoWindowOptions = {},
    options: BasicObject = {}
  ) => {
    const { name = '', category, address } = infoWindowInfo;
    const { showDetail, addBookmark, buttons = [], isHideBtn } = options;

    const infoWindowText = `
      <div class="map-info-window">
        <div class="map-info-window-title">${name}</div>
        <div class="map-info-window-category">${category}</div>
        <div class="map-info-window-address">${address}</div>

        <div class="map-info-window-btn-wrap"></div>
      </div>
    `;

    const infoWindowElement = parseHtmlText(infoWindowText);

    // 버튼이 있으면, 버튼을 추가
    if (buttons.length > 0) {
      const btnWrap = infoWindowElement.querySelector(
        '.map-info-window-btn-wrap'
      );

      buttons.forEach((button: BasicObject) => {
        const { text, color = '', onClick } = button;
        const btn = parseHtmlText(
          `<button class="map-info-window-btn ${color}">${text}</button>`
        );

        btn.onclick = () => {
          if (typeof onClick === 'function') onClick(infoWindowInfo);
        };
        btnWrap?.appendChild(btn);
      });
    }

    if (!isHideBtn) {
      const btnWrap = infoWindowElement.querySelector(
        '.map-info-window-btn-wrap'
      );
      const detailBtn = parseHtmlText(
        `<button class="map-info-window-btn map-info-window-btn-detail">상세보기</button>`
      );
      const bookmarkBtn = parseHtmlText(
        `<button class="map-info-window-btn map-info-window-btn-bookmark">찜하기</button>`
      );

      if (detailBtn)
        detailBtn.onclick = () => {
          if (typeof showDetail === 'function') showDetail(infoWindowInfo);
        };
      if (bookmarkBtn)
        bookmarkBtn.onclick = (event) => {
          if (typeof addBookmark === 'function') {
            const target = event.target as HTMLElement;
            addBookmark(infoWindowInfo, target);
          }
        };

      btnWrap?.appendChild(detailBtn);
      btnWrap?.appendChild(bookmarkBtn);
    }

    const infoWindow = new naver.maps.InfoWindow({
      content: infoWindowElement,
      maxWidth: 250,
      anchorSize: new naver.maps.Size(12, 12),
      disableAnchor: true,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    });

    return infoWindow;
  };

  // MapLocationInfo 객체를 naver.maps.LatLng 객체로 변환하는 함수
  const parseLatLng = ({ lat, lng }: MapLocationInfo) => {
    return new naver.maps.LatLng(lat, lng);
  };

  // {mapx, mapy} 객체를 naver.maps.LatLng 객체로 변환하는 함수
  const convertToLatLng = ({ mapx, mapy }: BasicObject) => {
    const tm128 = new naver.maps.Point(mapx, mapy);
    const { x: lng, y: lat } = naver.maps.TransCoord.fromTM128ToLatLng(tm128);
    return { lat, lng };
  };

  // 위치 정보로 주변 지역 검색어를 반환하는 함수
  const getPlaceSearchTerm = async (locationInfo?: any) => {
    const areaInfo: BasicObject = await getAreaInfo(locationInfo);
    const regionInfo = areaInfo.region;
    const areaText = regionInfo.area2.name + ' ' + regionInfo.area3.name;
    const placeSearchTerm = `${areaText} 맛집`;

    return placeSearchTerm;
  };

  // 장소정보 배열에서, 중복된 장소를 제거해서 리턴해주는 함수
  const getUniquePlaces = (
    places: BasicObject[] = [],
    newPlaces: BasicObject[] = []
  ): BasicObject[] => {
    // 중복된 장소는 제거
    const totalPlaces = [...places, ...newPlaces];
    const uniquePlaces: BasicObject[] = totalPlaces.reduce(
      (acc: BasicObject[], cur) => {
        const isExist = acc.find(
          (placeInfo: BasicObject) => placeInfo.name === cur.name
        );
        if (!isExist) {
          acc.push(cur);
        }
        return acc;
      },
      []
    );

    return uniquePlaces;
  };

  // 키워드로 장소 검색하는 함수
  function searchKeywordKakao(
    keyword: string,
    callback?: (result: BasicObject) => void,
    options?: BasicObject
  ) {
    kakaoPlace.keywordSearch(
      keyword,
      (data: BasicObject[], status: string, pagination: BasicObject) => {
        if (status === window.kakao.maps.services.Status.OK) {
          const convertedData = data.map((place) => convertToPlace(place));
          console.log('searchKeywordKakao() - convertedData', convertedData);
          console.log('searchKeywordKakao() - pagination', pagination);

          if (typeof callback === 'function')
            callback({ places: convertedData, pagination });
        } else {
          console.error('검색결과가 없습니다.');
          if (typeof callback === 'function')
            callback({ places: [], pagination });
        }
      },
      options
    );
  }

  // 데이터 형태 변환 함수
  function convertToPlace(rawData: BasicObject) {
    const {
      place_name: name,
      address_name: address,
      category_name: category,
      phone: phoneNumber,
      x: lng,
      y: lat,
    } = rawData;
    const locationInfo = { lat, lng };

    return {
      name,
      address,
      category,
      phoneNumber,
      locationInfo,
      meta: rawData,
    };
  }

  // 장소에 대한 정보를 복사하는 함수
  const copyPlace = (type: keyof PlaceInfo, placeInfo: PlaceInfo) => {
    const typeData = placeInfo[type] || '';

    let typeText = '';
    if (type === 'name') typeText = '가게 이름이';
    else if (type === 'address') typeText = '주소가';
    else if (type === 'phoneNumber') typeText = '전화번호가';

    if (!typeText) return;
    navigator.clipboard.writeText(typeData as string);
    showToast(`${typeText} 복사되었습니다.`);
  };

  return {
    checkSamePlace,
    checkBookmarkedPlace,
    getMyLocation,
    getAreaInfo,
    getAddress,
    getInfoWindow,
    parseLatLng,
    convertToLatLng,
    getPlaceSearchTerm,
    getUniquePlaces,
    searchKeywordKakao,
    convertToPlace,
    bookmarkPlace,
    copyPlace,
  };
};
