export const useEnv = () => {
  const isLocal = process.env.NODE_ENV === 'local';
  const isDev = process.env.NODE_ENV === 'development';
  const isDevLocal = isLocal || isDev;
  const isProd = process.env.NODE_ENV === 'production';

  return {
    isLocal,
    isDev,
    isDevLocal,
    isProd,
  };
};
